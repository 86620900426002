<template>
    <v-app>
        <v-main class="black">
            <v-container fill-height>
                <v-row align="center"
                       justify="center">
                    <v-col class="px-16" cols="12"><v-img
                        src="@/assets/logo-tc.png"
                    ></v-img></v-col>
                    <v-col align="center"><h1 class="white--text">ASK - THE SURVEY PLATFORM</h1></v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "WelcomeView"
}
</script>

<style scoped>
body{
    background: black;
}
img{
    max-width: 100%;
}

</style>
