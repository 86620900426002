<template>
  <div>
      <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
html { overflow-y: auto!important; }
</style>
