import axios from "axios";

const API_URL = 'https://api.tecnoclub.org/ask/'

export default {

    // GAUDI
    LOGIN_GAUDI(user, pass) {
        return axios.post(API_URL + 'login_gaudi.php', {
            username: user,
            password: pass
        })
    },
    RESUME_GAUDI(user, token) {
        return axios.post(API_URL + 'resume_gaudi.php', {
            username: user,
            token: token
        })
    },
    SUBMIT_GAUDI(client, answer, comment) {
        return axios.post(API_URL + 'submit_gaudi.php', {
            username: localStorage.getItem('user_gaudi'),
            token: localStorage.getItem('token_gaudi'),
            client: parseInt(client),
            answer: answer,
            comment: comment
        })
    },

}
