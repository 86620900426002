<template>

    <v-app>
        <v-app-bar
            app
            color="#444"
            dark
        >
            <img src="@/assets/logo-gaudi.png" alt="" height="50">
            <v-spacer></v-spacer>
            <img src="@/assets/logo-tc.png" alt="" height="50">
        </v-app-bar>
        <v-main>
            <v-dialog
                v-model="login"
                width="400"
                persistent
                overlay-color="red darken-1"
                overlay-opacity="0.8"
                @keydown.enter="checkLogin"

            >
                <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                        Iniciar sesión
                    </v-card-title>

                    <v-card-text>
                        <v-text-field
                            label="Usuario"
                            v-model="user"
                        ></v-text-field>
                        <v-text-field
                            label="Contraseña"
                            type="password"
                            v-model="password"
                        ></v-text-field>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red darken-1"
                            class="white--text"
                            @click="checkLogin"
                        >
                            ENTRAR
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="submited"
                width="400"
                persistent
            >
                <v-card flat>
                    <v-card-title class="text-h5 grey lighten-2">
                        Enviando datos...
                    </v-card-title>

                    <v-card-text align="center">
                        <v-progress-circular
                            :size="50"
                            :width="5"
                            color="red darken-1"
                            class="mt-5"
                            indeterminate
                        ></v-progress-circular>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="response"
                width="400"
                persistent
                overlay-color="green darken-1"
                overlay-opacity="0.8"
            >
                <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                        Datos enviados
                    </v-card-title>

                    <v-card-text class="pt-5">
                        <p>
                            La respuesta del cliente nº {{client}} ha sido grabada correctamente.
                        </p>
                        <h3 class="green--text">¡Gracias por su colaboración!</h3>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="red darken-1"
                            class="white--text"
                            @click="exit"
                        >
                            NUEVO FORMULARIO
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-container :style="blur ? 'filter: blur(5px)' : ''">
                <div class="background"></div>
                <v-card max-width="800" flat color="transparent">
                    <v-card-title>
                        <v-row>
                            <v-col><h2>PLAN FORMATIVO 2023</h2></v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Nº Cliente"
                                    type="number"
                                    v-model="client"
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>

                    </v-card-title>

                    <v-card-text>

                        <div v-for="(course, index) in courses" :key="index">
                            <v-row>
                                <v-col cols="7">
                                    <v-dialog
                                        width="500"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <h2
                                                v-bind="attrs"
                                                v-on="on"
                                                class="red--text text--darken-1">{{ course.title }}</h2>
                                        </template>

                                        <v-card>
                                            <v-card-title class="text-h5 grey lighten-2">
                                                {{ course.title }}
                                            </v-card-title>

                                            <v-card-text>
                                                <h1 class="mt-5">Índice</h1>
                                            </v-card-text>

                                            <v-card-text v-for="item in course.index" :key="item.section">
                                                <h2>- {{ item.section }}</h2>
                                                <h3 class="pl-5 mt-1" v-for="subsection in item.subsections" :key="subsection">{{ subsection }}</h3>
                                            </v-card-text>
                                        </v-card>
                                    </v-dialog>
                                    <h3 class="pt-2">{{ course.subtitle }}</h3>
                                </v-col>
                                <v-col cols="5">
                                    <v-rating
                                        v-model="course.rating"
                                        length="5"
                                    >
                                        <template v-slot:item="props">
                                            <v-icon
                                                large
                                                @click="props.click"
                                                :color="props.isFilled ? 'red darken-1' : ''"
                                            >
                                                mdi-numeric-{{ props.index + 1 }}-box{{ props.isFilled ? '' : '-outline' }}
                                            </v-icon>
                                        </template>
                                    </v-rating>
                                </v-col>
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                        </div>

                        <v-row>
                            <v-col>
                                <v-textarea
                                    label="Sugerencias"
                                    v-model="comment"
                                    auto-grow
                                    outlined
                                    rows="3"
                                ></v-textarea>
                            </v-col>
<!--                            <v-spacer></v-spacer>-->
                            <v-col cols="auto">
                                <v-btn
                                    :disabled="!valid"
                                    elevation="2"
                                    color="red darken-1"
                                    x-large
                                    class="mt-2 white--text"
                                    @click="submit"
                                >ENVIAR
                                </v-btn>
                            </v-col>
                        </v-row>


                    </v-card-text>


                </v-card>
            </v-container>
        </v-main>

    </v-app>

</template>

<script>
import api from "@/api";
import {COURSES} from "@/data/gaudi01";

export default {
    name: 'SurveyGaudi01',
    data: () => ({
        blur: true,
        login: false,
        user: '',
        password: '',
        client: '',
        courses: COURSES,
        submited: false,
        response: false,
        comment: ''
    }),
    computed: {
        valid() {
            let valid = true
            this.courses.forEach(course => {
                if (course.rating == 0) {
                    valid = false
                }
            })
            return valid && this.client > 0
        }
    },
    methods: {
        checkLogin() {
            api.LOGIN_GAUDI(this.user, this.password)
                .then(response => {
                    let data = response.data
                    console.log(data)

                    if (data['allowed']) {
                        localStorage.setItem('user_gaudi', data['username'])
                        localStorage.setItem('token_gaudi', data['token'])
                        this.login = false
                        this.blur = false
                    } else {
                        this.username = ''
                        this.password = ''
                    }
                })
        },
        submit() {
            this.submited = true
            let answer = ''
            this.courses.forEach(course => {
                answer += course.rating
            })
            api.SUBMIT_GAUDI(this.client, answer, this.comment)
                .then(response => {
                    if (response.data) {
                        this.submited = false
                        this.response = true
                    } else {
                        location.reload()
                    }
                });
        },
        exit() {
            location.reload()
        }
    },
    mounted() {
        let user = localStorage.getItem('user_gaudi')
        let token = localStorage.getItem('token_gaudi')
        if (user != null && token != null) {
            api.RESUME_GAUDI(user, token)
                .then(response => {
                    this.login = !response.data
                    this.blur = !response.data
                });
        } else {
            this.login = true
        }
    }
}
</script>

<style>
.background {
    background: url('../assets/bg-gaudi.png') center center no-repeat;
    background-size: cover;
    filter: opacity(50%);
    width: 100%;
    z-index: 0;
    min-height: 80%;
    position: fixed;
}
</style>
