import Vue from 'vue'
import VueRouter from 'vue-router'
import SurveyGaudi01 from './views/SurveyGaudi01.vue'
import WelcomeView from "@/views/WelcomeView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'welcome',
        component: WelcomeView
    }, {
        path: '/ag2023',
        name: 'ag2023',
        component: SurveyGaudi01
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
