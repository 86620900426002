let COURSES = [
    {
        title: 'Sensores y Actuadores',
        subtitle: 'Diagnosis de elementos',
        index: [
            {section: 'Lógica de diagnóstico', subsections: []},
            {section: 'Motores Gasolina', subsections: []},
            {section: 'Sistemas Diesel (Bomba Rotativa)', subsections: []},
            {section: 'Sistemas TDI (Inyector Bomba)', subsections: []},
            {section: 'Sistemas CommonRail', subsections: []},
            {section: 'Diagnóstico con máquina de diagnosis', subsections: []},
            {section: 'Lógica de comprobación de Sensores', subsections: []},
            {section: 'Lógica de comprobación de Actuadores', subsections: []},
        ]
    },
    {
        title: 'Seguimiento de esquemas',
        subtitle: 'Esquemas eléctricos originales',
        index: [
            {section: 'Interpretación de esquemas Grupo VAG', subsections: []},
            {section: 'Interpretación de esquemas Citroën', subsections: []},
            {section: 'Interpretación de esquemas Ford', subsections: []},
            {section: 'Interpretación de esquemas Renault', subsections: []},
            {section: 'Interpretación de esquemas BMW / Mini', subsections: []},
            {section: 'Interpretación de esquemas Bosch ESItronic', subsections: []},
            {section: 'Interpretación de esquemas Autodata', subsections: []},
            {section: 'Interpretación de esquemas Toyota / Lexus', subsections: []},
            {section: 'Interpretación de esquemas Kia / Hyundai', subsections: []},
            {section: 'Interpretación de esquemas Fiat / Alfa Romeo', subsections: []},
        ]
    },
    {
        title: 'Osciloscopio y oscilogramas',
        subtitle: 'Uso e interpretación',
        index: [
            {section: 'Principios de funcionamiento', subsections: []},
            {section: 'Parámetros principales', subsections: ['Ejes de coordenadas', 'Eje X', 'Eje Y']},
            {section: 'Interpretación de señales', subsections: ['Corriente continua', 'Corriente alterna']},
            {section: 'Magnitudes principales', subsections: ['Sobre eje X', 'Sobre eje Y']},
            {section: 'Trigger', subsections: []},
            {section: 'Sondas de medición', subsections: []},
            {section: 'Características de un osciloscopio', subsections: []},
            {section: 'Oscilogramas de corriente continua', subsections: []},
            {section: 'Señales cuadradas', subsections: []},
            {section: 'Oscilogramas de corriente alterna', subsections: []},
            {section: 'Sincronización de señales', subsections: []},
            {section: 'Valores de ajuste', subsections: ['Sensores', 'Actuadores']},
        ]
    },
    {
        title: 'Sistemas multiplexados',
        subtitle: 'de 2º y 3º Generación',
        index: [
            {
                section: 'Multiplexado PSA', subsections: [
                    'Interpretación de esquemática',
                    'Arquitecturas de red',
                    'LIN',
                    'CAN',
                    'MOST',
                    'Esquemas eléctricos',
                    'Bluetooth',
                    'Diagnosis CAN',
                    'Multiplexados PSA 2010 Plus',
                    'Multiplexados PSA 2010 ECO',
                ]
            },
            {section: 'Multiplexado VAG / BMW', subsections: [
                    'Interpretación de esquemática',
                    'Arquitecturas de red',
                    'LIN VAG',
                    'CAN VAG',
                    'MOST VAG',
                    'Esquemas eléctricos',
                    'Bluetooth',
                    'Diagnosis CAN',
                    'Multiplexado BMW',
                ]},
        ]
    },
    {
        title: 'Diagnosis Gasolina',
        subtitle: 'Vehículos de última generación',
        index: [
            {section: 'Ficha identificación del vehículo', subsections: []},
            {section: 'Localización de componentes', subsections: []},
            {section: 'Ficha de captura de datos', subsections: []},
            {section: 'Sistema de encendido', subsections: []},
            {section: 'Sistema de inyección', subsections: []},
            {section: 'Emisión de gases', subsections: []},
            {section: 'Otros', subsections: []},
            {section: 'Ficha de seguimiento', subsections: []},
            {section: 'Esquema eléctrico', subsections: []},
        ]
    },
    {
        title: 'Vehículos Bifuel',
        subtitle: 'GLP y GNC',
        index: [
            {section: '¿Qué es un vehículo Bifuel?', subsections: []},
            {section: 'Ventajas del Bifuel', subsections: ['Combustibles: composición, emisiones y características']},
            {section: 'Gasolina', subsections: ['GLP', 'GNC', 'Precios y consumos', 'Normas de seguridad']},
            {section: 'Elementos y diferencias GLP / GNC', subsections: []},
            {section: 'VW Passat Eco-Fuel 1.4l TSI 110Kw', subsections: []},
            {section: 'Opel Corsa 1.4l eco-FLEX 66Kw', subsections: []},
        ]
    },
    {
        title: 'Gasolina Inyección Directa',
        subtitle: 'TFSI, EcoBoost, PureTech',
        index: [
            {section: 'Grupo VAG TSI / FSI / TFSI', subsections: [
                    'Motores más pequeños (Downsize)',
                    'Turbos volumétricos',
                    'Distribución variable',
                    'Válvulas de alzada variable',
                    'Admisión de aire variable',
                    'Menor arrastre de correa auxiliar',
                    'Refrigeración pilotada',
                    'Start/Stop',
                    'Caja de cambios pilotada / de doble embrague',
                    'Aerodinámica',
                    'Neumáticos de baja rozadura',
                ]},
            {section: 'Ford EcoBoost / PSA PureTech', subsections: [
                    'Norma EURO6',
                    'Cronología normas EURO1- EURO5',
                    'Reducción catalítica selectiva',
                    'Diagnosis de gases de escape',
                    'Mantenimiento de la reducción catalítica',
                    'Inyección EcoBoost',
                    'Prácticas sobre vehículo',
                ]},
        ]
    },
    {
        title: 'Diagnosis Diesel',
        subtitle: 'Vehículos de última generación',
        index: [
            {section: 'Sistema Delphi DCM6.2', subsections: []},
            {section: 'Captadores', subsections: []},
            {section: 'Circuito de carburante', subsections: []},
            {section: 'Circuito de baja presión', subsections: []},
            {section: 'Circuito de alta presión', subsections: []},
            {section: 'Circuito de aire', subsections: []},
            {section: 'Funciones auxiliares', subsections: []},
            {section: 'Mantenimiento', subsections: []},
            {section: 'Prácticas sobre vehículo (Peugeot 508)', subsections: []},
        ]
    },
    {
        title: 'Sistemas Anticontaminación',
        subtitle: 'Gasolina y diesel, SCR, AdBlue, FAP, NOx',
        index: [
            {section: 'Gasolina', subsections: [
                    'Formación de la mezcla',
                    'Combustión',
                    'Emisiones contaminantes',
                    'Sistemas anticontaminación',
                    'Normativas de control de emisiones',
                    'Prácticas',
                ]},
            {section: 'Diesel - Filtro de partículas', subsections: [
                    'Descripción de un FAP',
                    'Elementos que intervienen',
                    'Sensores de temperatura',
                    'Sensor de presión diferencial',
                    'Aditivación de Cerina',
                    'Condiciones para la regeneración',
                    'Diagnosis y comprobaciones',
                ]},
            {section: 'Diesel - SCR', subsections: [
                    'Reducción catalítica selectiva - AdBlue',
                    'Depósito y bomba de aditivación',
                    'Calefacción',
                    'Inyector',
                    'Elementos del escape',
                    'Sensores de temperatura',
                    'Sonda lambda',
                    'Sensor de NOx',
                    'Catalizadores de oxidación y reducción',
                    'Mantenimiento y diagnosis',
                ]},
        ]
    },
    {
        title: 'Vehículos Híbridos',
        subtitle: 'e híbridos enchufables',
        index: [
            {section: 'Principios eléctricos', subsections: []},
            {section: 'Baterías', subsections: []},
            {section: 'Riesgos y prevención', subsections: []},
            {section: 'Principios de funcionamiento', subsections: []},
            {section: 'Tipos de vehículo híbrido', subsections: []},
            {section: 'Sistema de alta tensión', subsections: []},
            {section: 'Elementos y funcionalidad', subsections: []},
            {section: 'Freno regenerativo', subsections: []},
            {section: 'Cambio de doble embrague', subsections: []},
            {section: 'Sistemas multiplexados', subsections: []},
            {section: 'Esquemas eléctricos', subsections: []},
            {section: 'Hyundai Ioniq PHEV', subsections: []},
            {section: 'Prácticas sobre vehículo', subsections: []},
        ]
    },
    {
        title: 'Sistemas Myld Hibrid 48V',
        subtitle: 'Gasolina y Diesel',
        index: [
            {section: 'Norma EURO7 (2025)', subsections: []},
            {section: 'Implantación del vehículo eléctrico', subsections: []},
            {section: 'Vehículo con pila de combustible', subsections: []},
            {section: 'Tipos y funcionalidades', subsections: []},
            {section: 'Vehículo microhíbrido', subsections: []},
            {section: 'Clasificación de microhíbridos', subsections: []},
            {section: 'Elementos del sistema microhíbrido', subsections: []},
            {section: 'Sistemas auxiliares al microhíbrido', subsections: []},
            {section: 'Kia Rio 1.0 T-GDI MHEV IMT 74Kw', subsections: []},
            {section: 'Pruebas prácticas', subsections: []},
        ]
    },
    {
        title: 'Vehículos Eléctricos',
        subtitle: 'Funcionalidad y diagnosis',
        index: [
            {section: 'Principios eléctricos', subsections: []},
            {section: 'Riesgos eléctricos de alto voltaje', subsections: []},
            {section: 'Alta tensión sobe el cuerpo humano', subsections: []},
            {section: 'Equipos de seguridad', subsections: []},
            {section: 'Desconexión de alto voltaje', subsections: []},
            {section: 'Baterías', s1ubsections: []},
            {section: 'El motor eléctrico', subsections: ['Motor asíncrono', 'Motor síncrono']},
            {section: 'Elementos del motor eléctrico', subsections: []},
            {section: 'Freno regenerativo', subsections: []},
            {section: 'De Motor a Generador', subsections: []},
            {section: 'Sistema de frenado', subsections: []},
            {section: 'Prácticas Renault ZOE', subsections: []},
        ]
    },
    {
        title: 'Sistemas Start-Stop',
        subtitle: 'Funcionalidad y características',
        index: [
            {section: 'Start/Stop 1ª gen (-2015)', subsections: []},
            {section: 'Start/Stop 2ª y 3ª gen (2015-)', subsections: []},
            {section: 'Grupo VAG y PSA', subsections: []},
            {section: 'Sensores y actuadores', subsections: []},
            {section: 'Verificación y control', subsections: ['Arranque', 'Alterno-arranque', 'Batería']},
            {section: 'Diagnosis con máquina', subsections: []},
            {section: 'Seguimiento de esquema eléctrico', subsections: []},
            {section: 'Prácticas sobre vehículo', subsections: []},
        ]
    },
    {
        title: 'Climatización automática',
        subtitle: 'Diagnosis y mantenimiento',
        index: [
            {section: 'Impacto y Normativa Medioambiental', subsections: []},
            {section: 'Introducción a la refrigeración', subsections: []},
            {section: 'Física aplicada', subsections: []},
            {section: 'Fluidos frigoríficos', subsections: []},
            {section: 'Sistemas del aire acondicionado', subsections: []},
            {section: 'Instalación eléctrica', subsections: []},
            {section: 'Climatización', subsections: ['Automática', 'Semiautomática']},
            {section: 'Diagnostico y reparación', subsections: ['Manómetro y visor', 'Valores termodinámicos']},
            {section: 'Averías frecuentes', subsections: []},
            {section: 'Peugeot 508', subsections: ['Esquema eléctrico', 'Comprobaciones', 'Prácticas sobre vehículo']},
        ]
    },
    {
        title: 'Cambios automáticos',
        subtitle: 'Diagnosis y mantenimiento',
        index: [
            {section: 'Historia', subsections: []},
            {section: 'Ventajas', subsections: []},
            {section: 'Desventajas', subsections: []},
            {section: 'Tipos de cambio', subsections: ['Manejo', 'Mantenimiento']},
            {section: 'Mantenimiento', subsections: ['Tipos de ATF', 'Kits de mantenimiento']},
            {section: 'Procedimientos originales', subsections: ['Mercedes Epicicloidales', 'PSA Pilotados', 'Grupo VAG DSG', 'Ford CVT']},
            {section: 'Averías frecuentes', subsections: []},
            {section: 'Curso teórico', subsections: []},
        ]
    },
]

//DEV
// COURSES.forEach(c => { c.rating = 1 })

export {COURSES}
